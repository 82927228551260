((document): void => {
  'use strict';

  let $memoriumLink: HTMLElement;
  let $memoriumCloseBtn: HTMLElement;
  let $memoriumEl: HTMLElement;
  let $mobileNavBtn: HTMLElement;
  let $mobileNavCloseBtn: HTMLElement;
  let $mobileNavEl: HTMLElement;
  let $daysEl: HTMLElement;
  let $dateEl: HTMLElement;
  let $currentYearEl: HTMLElement;

  const _bind = (): void => {
    $memoriumLink = document.querySelector('.memorium-link');
    $memoriumCloseBtn = document.querySelector('.memorium-close-btn');
    $memoriumEl = document.querySelector('#memoriam');
    $mobileNavBtn = document.querySelector('.mobile-nav-btn');
    $mobileNavCloseBtn = document.querySelector('.nav-bar--close');
    $mobileNavEl = document.querySelector('.nav-bar--nav');
    $daysEl = document.querySelector('.day-counter .days');
    $dateEl = document.querySelector('.trip-date .date');
    $currentYearEl = document.querySelector('#current-year');
  };

  const _listen = (): void => {
    $memoriumLink.addEventListener('click', _toggleMemoriumView);
    $memoriumCloseBtn.addEventListener('click', _toggleMemoriumView);
    $mobileNavBtn.addEventListener('click', _toggleMobileNavView);
    $mobileNavCloseBtn.addEventListener('click', _toggleMobileNavView);
  };

  const _toggleMemoriumView = (): void => {
    $memoriumEl.classList.toggle('hidden');
  };

  const _toggleMobileNavView = (): void => {
    $mobileNavEl.classList.toggle('hidden');
  };

  const _setCurrentYear = (): void => {
    $currentYearEl.innerHTML = (new Date().getFullYear()).toString();
  };

  const _init = (): void => {
    _bind();
    _listen();
    _setCurrentYear();
  };

  document.addEventListener('DOMContentLoaded', _init);
})(document);
